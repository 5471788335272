/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import TitleDefault from 'components/elements/TitleDefault'
import ParseContent from 'components/shared/ParseContent'

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      content
      # acf {
      #   image {
      #     localFile {
      #       childImageSharp {
      #         fluid(quality: 100, maxWidth: 1920) {
      #           ...GatsbyImageSharpFluid_noBase64
      #         }
      #         meta: fluid(maxWidth: 800, toFormat: JPG) {
      #           src
      #         }
      #       }
      #     }
      #   }
      # }
      # yoastMeta: yoast_meta {
      #   name
      #   content
      #   property
      # }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoastMeta, content },
  },
}) => {
  return (
    <Layout>
      {yoastMeta && (
        <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      )}
      {/* <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={image.localFile.childImageSharp.fluid.src}
        article
      /> */}
      <div className="container py-5">
        <TitleDefault>{title}</TitleDefault>
        <ParseContent content={content} />
      </div>
    </Layout>
  )
}

export default PageTemplate
